<template>
    <div class="login-form">
        <ol
            class="login-form__step-indicator"
            aria-label="Stappen"
        >
            <li :aria-current="currentStep === STEP_1 ? 'step' : ''">
                <small>Stap 1: E-mail</small>
            </li>
            <li :aria-current="currentStep === STEP_2 ? 'step' : ''">
                <small>Stap 2: Code</small>
            </li>
        </ol>

        <Transition
            name="form-switch"
            mode="out-in"
        >
            <div
                v-if="currentStep === STEP_1"
                class="login-form__part"
            >
                <div class="login-form__intro">
                    <h2>
                        Inloggen bij TLN
                    </h2>

                    <p>
                        Vul je e-mailadres in en ontvang een e-mail met jouw eenmalige inlogcode. <br>
                        <small>Let op: deze code is 15 minuten geldig.</small>
                    </p>
                </div>

                <form
                    class="login-form__body"
                    @submit.prevent="submitStep1"
                >
                    <BaseFormField
                        :required="true"
                        :errors="[errors.email]"
                    >
                        <template #label>
                            <label for="email">E-mailadres</label>
                        </template>

                        <template #default>
                            <BaseInput
                                id="email"
                                v-model="emailField"
                                type="text"
                                placeholder="Voer hier je (zakelijke) e-mailadres in"
                            />
                        </template>
                    </BaseFormField>

                    <BaseButton
                        :disabled="isSubmitting"
                        icon="arrow-right"
                    >
                        Inlogcode opvragen
                    </BaseButton>

                    <BaseButton
                        :element="NuxtLink"
                        to="/account-aanmaken"
                        class="base-button--transparent-light"
                    >
                        Nog geen account? Meld je aan
                    </BaseButton>
                </form>
            </div>

            <div
                v-else-if="currentStep === STEP_2"
                class="login-form__part"
            >
                <div class="login-form__intro">
                    <h2>
                        Jouw inlogcode
                    </h2>

                    <p>
                        We hebben een inlogcode verstuurd naar <em>{{ emailAddress || 'je e-mailadres' }}</em>. Controleer je mail en vul de code hieronder in.
                    </p>
                </div>

                <form
                    class="login-form__body"
                    @submit.prevent="submitStep2"
                >
                    <BaseFormField
                        :required="true"
                        :errors="[errors.code]"
                    >
                        <template #label>
                            <label for="code">Inlogcode</label>
                        </template>

                        <template #default>
                            <BaseInput
                                id="code"
                                v-model="codeField"
                                type="text"
                                placeholder="Vul hier de inlogcode uit je e-mail in"
                                maxlength="4"
                            />
                        </template>
                    </BaseFormField>

                    <BaseButton
                        :disabled="isSubmitting"
                        icon="arrow-right"
                    >
                        Inloggen
                    </BaseButton>

                    <BaseButton
                        type="button"
                        :disabled="resendTimerTime"
                        class="base-button--transparent-light"
                        size="medium"
                        @click="resetField('code'); submitStep1({ email: emailAddress })"
                    >
                        Code opnieuw verzenden
                        <span v-if="resendTimerTime > 0">
                            (over {{ resendTimerTime }} sec)
                        </span>
                    </BaseButton>
                </form>
            </div>
        </Transition>
    </div>
</template>


<script setup>
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useAuthStore } from '~/store/auth';
import { userApi } from '~/utils/userApi';

const authStore = useAuthStore();
const route = useRoute();

const STEP_1 = 1;
const STEP_2 = 2;
const RESEND_TIMEOUT = 20;

const currentStep = ref(STEP_1);
const isSubmitting = ref(false);
const emailAddress = ref('');
const resendTimer = ref(null);
const resendTimerTime = ref(RESEND_TIMEOUT);

/**
 * Form validation.
 */
const validationSchema = object({
    email: string('Vul een geldig e-mailadres in.')
        .email('Vul een geldig e-mailadres in.')
        .required('Vul een geldig e-mailadres in.'),
    code: string('Vul een geldige inlogcode in.')
        .length(4, 'De inlogcode dient uit 4 cijfers te bestaan.')
});

const { errors, setErrors, defineField, resetField, handleSubmit } = useForm({
    validationSchema,
});

const [emailField] = defineField('email', {
    validateOnModelUpdate: false,
});
const [codeField] = defineField('code', {
    validateOnModelUpdate: false,
});

/**
 * Handle step 1 submit.
 */
const submitStep1 = handleSubmit(async(values) => {

    isSubmitting.value = true;

    try {
        await userApi('auth/code', {
            method: 'POST',
            body: {
                email: values.email,
            }
        });

        // Set next step and store submitted email.
        currentStep.value = STEP_2;
        emailAddress.value = values.email;

        // Clear and reset resend button timer.
        clearInterval(resendTimer.value);
        resendTimerTime.value = RESEND_TIMEOUT;
        resendTimer.value = setInterval(() => {
            if (resendTimerTime.value > 0) {
                resendTimerTime.value -= 1;
            } else {
                clearInterval(resendTimer.value);
                resendTimer.value = null;
            }
        }, 1000);

        isSubmitting.value = false;

    } catch(error) {

        isSubmitting.value = false;

        // Handle `The selected email is invalid.`.
        if (error?.response?.status === 422) {
            setErrors({
                email: 'Je e-mailadres is niet bekend bij ons. Probeer het opnieuw of maak een account aan via onderstaande link.',
            });
        // Show generic error as fallback.
        } else {
            setErrors({
                email: 'Er is iets misgegaan. Probeer het later opnieuw.',
            });
        }

    }
});

/**
 * Handle step 2 submit.
 */
const submitStep2 = handleSubmit(async(values) => {

    isSubmitting.value = true;

    try {
        const response = await userApi('auth/login', {
            method: 'POST',
            body: {
                email: values.email,
                code: values.code,
            }
        });

        if (response.success && response.token) {
            const cookie = await useJwtToken();
            const hasLoggedInCookie = useCookie('tln-has-logged-in');

            hasLoggedInCookie.value = true;
            cookie.value = response.token;
            authStore.jwtToken = response.token;

            await authStore.fetchUser();

            if (route.query?.redirect_to) {
                await navigateTo(decodeURIComponent(route.query.redirect_to), {
                    external: true
                });
            } else {

                await navigateTo(authStore.initialPage || '/mijn-tln/profiel');
            }
        }

        isSubmitting.value = false;

    } catch(error) {

        // Handle `Code is invalid.`.
        if (error?.response?.status === 422) {
            setErrors({
                code: 'Deze code is niet (meer) geldig. Probeer het opnieuw, of vraag hieronder een nieuwe code aan.',
            });
        // Show generic error as fallback.
        } else {
            setErrors({
                code: 'Er is iets misgegaan. Probeer het later opnieuw.',
            });
        }

        isSubmitting.value = false;
    }
});

const NuxtLink = resolveComponent('NuxtLink');
</script>


<style lang="less" src="./LoginForm.less"></style>
